const llm_url = 'http://llm-backend.local/api/v1/llm/';

export const fetchThreads = async () => {
  const response = await $axios.get(llm_url + 'threads');
  return response.data.data;
}
export const fetchThread = async (barcode) => {
  const response = await $axios.get(llm_url + 'threads/msg?barcode=' + barcode);
  return response.data.data;
}
export const createMessage = async (message, barcode) => {
  const response = await $axios.post(llm_url + 'threads/messages/create', {
    message,
    barcode
  });
  return response.data;
}
export const createAutoMessage = async (message, barcode) => {
  const response = await $axios.post(llm_url + 'auto-threads/messages/create', {
    message,
    barcode
  });
  return response.data;
}
export const createThread = async ({
  test_type,
  barcode,
  patient_name,
  patient_gender
}) => {
  const response = await $axios.post(llm_url + 'threads/create', {
    test_type,
    barcode,
    patient_name,
    patient_gender
  });
  return response.data;
}
export const createAutoThread = async ({
  test_type,
  barcode,
  patient_name,
  patient_gender
}) => {
  const response = await $axios.post(llm_url + 'auto-threads/create', {
    test_type,
    barcode,
    patient_name,
    patient_gender
  });
  return response.data;
}

export const generateAutoMessage = async (barcode) => {
  const response = await $axios.post(llm_url + 'auto-threads/generate', {
    barcode
  });
  return response.data;
}
export const nextSampleAutoMessage = async (barcode) => {
  const response = await $axios.post(llm_url + 'auto-threads/next-sample', {
    barcode
  });
  return response.data;
}

export const showAnalysis = async (barcode) => {
  const response = await $axios.get(llm_url + 'analysis-threads/messages?barcode=' + barcode);
  return response.data;
}
export const generateAnalysis = async (barcode) => {
  const response = await $axios.post(llm_url + 'analysis-threads/generate', {
    barcode
  });
  return response.data;
}
export const checkRun = async (run_id, thread_id) => {
  const response = await $axios.post(llm_url + 'runs/check', {
    run_id,
    thread_id
  });
  return response.data;
}